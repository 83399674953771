import {
  Alert,
  Box,
  Button,
  createTheme,
  CssBaseline,
  Theme,
  ThemeOptions,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import i18n from "i18next";
import { FC, ReactNode, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { initReactI18next, useTranslation } from "react-i18next";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { checkSessionCookie } from "./api/cloudFunctions/sso/checkSessionCookie";
import { loginWithCustomToken } from "./api/cloudFunctions/sso/loginWithCustomToken";
import "./App.css";
import enJson from "./data/locales/en.json";
import jaJson from "./data/locales/ja.json";
import { logger } from "./lib/logger";
import { CommonFooter } from "./views/common/CommonFooter";
import { CommonHeader } from "./views/common/CommonHeader";
import { Edit } from "./views/pages/Edit";
import { Format } from "./views/pages/Format";
import { FromOmopa2 } from "./views/pages/FromOmopa2";
import { FromPuzsq } from "./views/pages/FromPuzsq";
import { Home } from "./views/pages/Home";
import { Login } from "./views/pages/Login";
import { Schema } from "./views/pages/Schema";
import { Maintenance } from "./views/pages/staticPages/Maintenance";
import { NowLoading } from "./views/pages/staticPages/NowLoading";
import { Write } from "./views/pages/Write";

type WrapperProps = {
  children?: ReactNode;
  pcHideOnScroll?: boolean;
};

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#1b5e20",
    },
    secondary: {
      main: "#f50057",
    },
  },
};
const theme = createTheme(themeOptions);

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enJson,
    },
    ja: {
      translation: jaJson,
    },
  },
  lng: window.localStorage.getItem("language") ?? "ja",
  fallbackLng: false,
  returnEmptyString: false,
  interpolation: { escapeValue: false },
});

export const Wrapper: FC<WrapperProps> = (props) => {
  const { pcHideOnScroll } = props;

  const isMobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const language =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language;
  const languageSetting = window.localStorage.getItem("language");
  const [, i18n] = useTranslation();

  return (
    <>
      <CommonHeader hideOnScroll={pcHideOnScroll && !isMobileSize} />
      <Box sx={{ mt: 10, mx: "auto", px: 1 }} maxWidth="lg">
        {!languageSetting && language !== "ja" && (
          <Alert
            variant="filled"
            action={
              <Button color="inherit" size="small">
                Update!
              </Button>
            }
            sx={{ width: "80%", my: 2, mx: "auto" }}
            onClick={() => {
              i18n.changeLanguage("en");
              window.localStorage.setItem("language", "en");
            }}
          >
            Puzzle Square is now available in English!
          </Alert>
        )}
        {props.children}
      </Box>
      <CommonFooter />
    </>
  );
};

const App = () => {
  console.log(process.env.REACT_APP_MAINTENANCE);
  if (process.env.REACT_APP_MAINTENANCE === "true") {
    return <Maintenance />;
  } else {
    return <Components />;
  }
};

const Components = () => {
  /**
   * uid ===nullならばログイン状況判定中
   * uid === ""ならばゲストモード
   * それ以外ならばログイン済み
   */
  const [uid, setUid] = useState<null | string>(null);
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const isLocalDebug = process.env.REACT_APP_CONTEXT === "LOCAL";

  useEffect(() => {
    //1. puzsq側でログイン状況の確認
    if (loading) {
      return;
    }
    //2. sessionCookieで認証
    checkSessionCookie().then(({ uid, customToken }) => {
      if (user !== null && user !== undefined) {
        if ((uid !== null && customToken !== null) || isLocalDebug) {
          if (uid === user.uid || isLocalDebug) {
            //2-1. 矛盾しない
            logger("case2-1", user.uid);
            setUid(user.uid);
          } else {
            logger("case2-2");
            logger(uid, user.uid);
            //2-2. 矛盾しているのでログアウト+sessionCookieをもとに再ログイン
            signOut(auth).then(() => {
              loginWithCustomToken(customToken!).then((result) => {
                setUid(result);
              });
            });
          }
        } else {
          logger("case2-3");
          //2-3. sessionCookie再発行する
          window.location.href = `https://logicpuzzle.app/refresh/${process.env.REACT_APP_LOGIN_URL}`;
        }
      } else {
        if (uid !== null && customToken !== null) {
          logger("case4-1");
          //4-1. sessionCookieをもとにログイン
          loginWithCustomToken(customToken).then((result) => {
            setUid(result);
          });
        } else {
          logger("case4-2");
          //4-2. ゲストモード
          setUid("");
        }
      }
    });
  }, [loading, auth, user, isLocalDebug]);

  useEffect(() => {
    if (uid === null) {
      return;
    } else if (uid === "") {
      //ゲストモード
      if (
        location.pathname === "/login" ||
        location.pathname === "/firstlogin"
      ) {
        navigate("/");
      }
    } else {
      if (location.pathname === "/login") {
        //ログイン成功
        navigate("/");
      }
    }
  }, [uid, location.pathname, navigate]);

  console.log("app");
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {loading || uid === null ? (
        <>
          <Wrapper>
            <NowLoading />
          </Wrapper>
        </>
      ) : (
        <>
          <Routes>
            <Route
              path="/"
              element={
                <Wrapper>
                  <Home />
                </Wrapper>
              }
            />
            <Route path="/login" element={<Login setUid={setUid} />} />
            <Route
              path="/write"
              element={
                <Wrapper>
                  <Write />
                </Wrapper>
              }
            />
            <Route
              path="/schema"
              element={
                <Wrapper>
                  <Schema />
                </Wrapper>
              }
            />
            <Route
              path="/format"
              element={
                <Wrapper>
                  <Format />
                </Wrapper>
              }
            />
            <Route
              path="/edit"
              element={
                <Wrapper>
                  <Edit />
                </Wrapper>
              }
            />
            <Route
              path="/fromPuzsq"
              element={
                <Wrapper>
                  <FromPuzsq />
                </Wrapper>
              }
            />
            <Route
              path="/fromOmopa2"
              element={
                <Wrapper>
                  <FromOmopa2 />
                </Wrapper>
              }
            />
          </Routes>
        </>
      )}
    </ThemeProvider>
  );
};

export default App;
