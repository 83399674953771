import { DocumentData } from "firebase/firestore";
import {
  PuzzleKind,
  PuzzleKindTable,
  PuzzleKindUnit,
} from "../../../types/PuzzleKind";

export const puzzleKindConverter = {
  toFirestore(post: PuzzleKind): DocumentData {
    return post;
  },
  fromFirestore(snapshot: DocumentData): PuzzleKind {
    const doc = snapshot;
    return doc.data() as PuzzleKind;
  },
};

export const puzzleKindTableConverter = {
  toFirestore(post: PuzzleKindTable): DocumentData {
    return post;
  },
  fromFirestore(snapshot: DocumentData): PuzzleKindTable {
    const doc = snapshot;
    return doc.data() as PuzzleKindTable;
  },
};
