import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { getAuth } from "firebase/auth";
import { FieldValue, serverTimestamp } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { auth } from "../../..";
import {
  deletePuzzleKind,
  savePuzzleKind,
} from "../../../api/firestore/loader/loadPuzzleKind";
import { isKanji } from "../../../lib/charset";
import { PuzzleKindUnit, Rule } from "../../../types/PuzzleKind";

type MainGridProps = {
  index: number;
  setIndex: (index: number) => void;
  size: number;
  setId: (id: string) => void;
  rows: PuzzleKindUnit[];
  mode: "edit" | "view";
};
export const MainGrid = (props: MainGridProps) => {
  const { index, setIndex, size, setId, rows, mode } = props;
  const [t, i18n] = useTranslation();
  const [ruleIndex, setRuleIndex] = useState("0");
  const [puzzleKindUnit, setPuzzleKindUnit] = useState<PuzzleKindUnit | null>(
    null
  );
  const navigate = useNavigate();
  const [rules, setRules] = useState<Rule[]>([]);
  const [akaJa, setAkaJa] = useState("");
  const [akaEn, setAkaEn] = useState("");
  useEffect(() => {
    if (!rows[index]) {
      return;
    }
    setPuzzleKindUnit(JSON.parse(JSON.stringify(rows[index])));
    setId(rows[index].id);
    window.location.hash = rows[index].id;
    const ruleIndex = rows[index].rules.findIndex(
      (rule) => rule.language === i18n.language
    );
    setRuleIndex(ruleIndex === -1 ? "0" : ruleIndex.toString());
    setAkaJa(rows[index].akaJa.join(", "));
    setAkaEn(rows[index].akaEn.join(", "));
  }, [index, rows]);

  useEffect(() => {
    if (puzzleKindUnit === null) {
      return;
    }
    setRules(puzzleKindUnit.rules);
  }, [puzzleKindUnit?.rules, i18n.language]);

  const handleChangeRuleTextIndex = useCallback((event: SelectChangeEvent) => {
    setRuleIndex(event.target.value as string);
  }, []);

  const handleChangeNameJa = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (puzzleKindUnit === null) {
        return;
      }
      const newPuzzleKindUnit = { ...puzzleKindUnit };
      newPuzzleKindUnit.nameJa = event.target.value;
      setPuzzleKindUnit(newPuzzleKindUnit);
    },
    [puzzleKindUnit]
  );
  const handleChangeNameEn = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (puzzleKindUnit === null) {
        return;
      }
      const newPuzzleKindUnit = { ...puzzleKindUnit };
      newPuzzleKindUnit.nameEn = event.target.value;
      setPuzzleKindUnit(newPuzzleKindUnit);
    },
    [puzzleKindUnit]
  );

  const handleCreate = useCallback(() => {
    setIndex(-1);
    const id = Math.random().toString(36).slice(-8);
    setId(id);
    setPuzzleKindUnit({
      id,
      nameJa: "",
      nameEn: "",
      author: {
        ja: "",
        en: "",
      },
      akaJa: [],
      akaEn: [],
      clueElements: "",
      examples: [],
      firstAppearance: {
        ja: "",
        en: "",
      },
      gridType: "",
      ruleElements: "",
      rules: [
        {
          text: "",
          source: "",
          language: "ja",
        },
      ],
      symbols: "",
      memo: "",
      writeInCategories: "",
      writeInSubcategories: "",
      lastEditBy: "",
      lastEditAt: null,
    });
  }, []);

  const handleDuplicate = useCallback(() => {
    if (puzzleKindUnit === null) {
      return;
    }
    const newPuzzleKindUnit = JSON.parse(JSON.stringify(puzzleKindUnit));
    newPuzzleKindUnit.id = Math.random().toString(36).slice(-8);
    setPuzzleKindUnit(newPuzzleKindUnit);
    setIndex(-1);
    setId(newPuzzleKindUnit.id);
  }, [puzzleKindUnit]);

  const handleChangeRuleText = useCallback(
    (e: { target: { value: string } }) => {
      setRules((prev) => {
        const newRule = [...prev];
        newRule[Number(ruleIndex)].text = e.target.value;
        return newRule;
      });
    },
    [ruleIndex]
  );

  const saveUnit = useCallback(() => {
    if (puzzleKindUnit === null) {
      return;
    }
    const newPuzzleKindUnit = { ...puzzleKindUnit };
    console.log(rules);
    newPuzzleKindUnit.rules = rules;
    newPuzzleKindUnit.akaJa = akaJa === "" ? [] : akaJa.split(/\s*,\s*/);
    newPuzzleKindUnit.akaEn = akaEn === "" ? [] : akaEn.split(/\s*,\s*/);
    newPuzzleKindUnit.lastEditBy = auth?.currentUser?.uid ?? "";
    newPuzzleKindUnit.lastEditAt = serverTimestamp();
    savePuzzleKind(newPuzzleKindUnit);

    console.log("save");
  }, [puzzleKindUnit, rules, akaJa, akaEn]);

  const deleteUnit = useCallback(() => {
    if (puzzleKindUnit === null) {
      return;
    }
    deletePuzzleKind(puzzleKindUnit.id);
  }, [puzzleKindUnit]);

  return puzzleKindUnit ? (
    <Grid2 xs={8}>
      <Paper
        sx={{
          px: 2,
        }}
      >
        {mode === "edit" && (
          <Box
            sx={{
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              <Button onClick={handleCreate}>{t("新規作成")}</Button>
              <Button onClick={handleDuplicate}>{t("複製")}</Button>
              <Button
                onClick={() => {
                  deleteUnit();
                }}
                variant="outlined"
              >
                {t("削除")}
              </Button>
            </ButtonGroup>
            <Button
              onClick={() => {
                navigate("/#" + puzzleKindUnit.id);
              }}
              variant={"outlined"}
              sx={{
                float: "right",
              }}
            >
              {t("編集モードを終了")}
            </Button>
          </Box>
        )}
        {mode === "view" && (
          <Box
            sx={{
              width: "100%",
              maxWidth: "800px",
            }}
          >
            {
              <Button
                onClick={() => {
                  navigate("/edit#" + puzzleKindUnit.id);
                }}
                variant={"outlined"}
                sx={{
                  float: "right",
                }}
              >
                {t("編集")}
              </Button>
            }
          </Box>
        )}
      </Paper>
      <Paper
        sx={{
          px: 2,
          py: 2,
        }}
      >
        <p
          style={{
            textAlign: "right",
            color: "#888",
            margin: 0,
            fontSize: "0.8em",
          }}
        >
          #{puzzleKindUnit.id}
        </p>
        {(mode === "edit" || i18n.language === "ja") && (
          <>
            <TextField
              label={t("puzzleKind.name")}
              variant="standard"
              value={puzzleKindUnit.nameJa}
              onChange={handleChangeNameJa}
              InputProps={{
                readOnly: mode === "view",
              }}
            />
            <TextField
              label={t("読みがな")}
              variant="standard"
              value={
                !isKanji(puzzleKindUnit.nameJa)
                  ? puzzleKindUnit.nameJa.replace(
                      /[\u30a1-\u30f6]/g,
                      function (match) {
                        var chr = match.charCodeAt(0) - 0x60;
                        return String.fromCharCode(chr);
                      }
                    )
                  : puzzleKindUnit.yomi ?? ""
              }
              onChange={(e) => {
                setPuzzleKindUnit((prev) => {
                  if (prev === null) {
                    return null;
                  }
                  const newPuzzleKindUnit = { ...prev };
                  newPuzzleKindUnit.yomi = e.target.value;
                  return newPuzzleKindUnit;
                });
              }}
              sx={{
                mx: 2,
                width: "200px",
              }}
              InputProps={{
                readOnly: mode === "view",
              }}
              disabled={!isKanji(puzzleKindUnit.nameJa)}
            />
            <TextField
              label={t("puzzleKind.aka")}
              variant="standard"
              value={akaJa}
              onChange={(e) => {
                setAkaJa(e.target.value);
              }}
              sx={{
                mx: 2,
                width: "200px",
              }}
              InputProps={{
                readOnly: mode === "view",
              }}
            />
          </>
        )}
        {(mode === "edit" || i18n.language === "en") && (
          <>
            <TextField
              label={t("puzzleKind.nameEn")}
              variant="standard"
              value={
                mode === "view" && puzzleKindUnit.nameEn === ""
                  ? puzzleKindUnit.nameJa
                  : puzzleKindUnit.nameEn
              }
              onChange={handleChangeNameEn}
              InputProps={{
                readOnly: mode === "view",
              }}
            />
            <TextField
              label={t("puzzleKind.akaEn")}
              variant="standard"
              value={akaEn}
              onChange={(e) => {
                setAkaEn(e.target.value);
              }}
              sx={{
                mx: 2,
                width: "400px",
              }}
              InputProps={{
                readOnly: mode === "view",
              }}
            />
          </>
        )}
        <Box
          sx={{
            width: "100%",
            maxWidth: "800px",
          }}
        >
          {mode === "edit" && (
            <>
              <IconButton
                aria-label="add"
                sx={{ my: 2 }}
                onClick={() => {
                  setRules((prev) => {
                    const newRule = [...prev];
                    newRule.splice(Number(ruleIndex) + 1, 0, {
                      text: "",
                      source: "",
                      language: i18n.language as "ja" | "en",
                    });
                    return newRule;
                  });
                  setRuleIndex((prev) => {
                    return String(Math.min(Number(prev) + 1, rules.length));
                  });
                }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                sx={{ my: 2 }}
                onClick={() => {
                  setRules((prev) => {
                    const newRule = [...prev];
                    newRule.splice(Number(ruleIndex), 1);
                    return newRule;
                  });
                  setRuleIndex((prev) => {
                    return String(Number(prev));
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}

          <FormControl sx={{ mx: 1, my: 2, maxWidth: "300px" }}>
            <InputLabel>{t("puzzleKind.ruleTextIndex")}</InputLabel>
            <Select
              value={"" + ruleIndex}
              label={t("puzzleKind.ruleTextIndex")}
              onChange={handleChangeRuleTextIndex}
              sx={{
                width: "240px",
              }}
            >
              {rules.map((rule, index) => {
                return (
                  <MenuItem value={"" + index} key={"" + index}>
                    #{index + 1} {rule.source}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            label={t("puzzleKind.ruleSource")}
            variant="standard"
            value={rules[Number(ruleIndex)]?.source ?? ""}
            onChange={(e) => {
              setRules((prev) => {
                const newRule = [...prev];
                newRule[Number(ruleIndex)].source = e.target.value;
                return newRule;
              });
            }}
            disabled={rules.length === 0}
            sx={{ my: 2, mx: 1, width: "240px", maxWidth: "240px" }}
            InputProps={{
              readOnly: mode === "view",
            }}
          />
          <FormControl sx={{ mx: 1, my: 2, maxWidth: "70px" }}>
            <InputLabel>{t("puzzleKind.language")}</InputLabel>
            <Select
              disabled={rules.length === 0}
              value={rules[Number(ruleIndex)]?.language ?? i18n.language}
              label={t("puzzleKind.language")}
              onChange={(event) => {
                setRules((prev) => {
                  const newRule = [...prev];
                  newRule[Number(ruleIndex)].language = event.target.value as
                    | "ja"
                    | "en";
                  return newRule;
                });
              }}
              inputProps={{ readOnly: mode === "view" }}
              sx={{
                width: "70px",
              }}
            >
              <MenuItem value={"ja"} key={"ja" + index}>
                Ja
              </MenuItem>
              <MenuItem value={"en"} key={"en" + index}>
                En
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <TextField
          multiline
          rows={10}
          value={rules[Number(ruleIndex)]?.text ?? ""}
          label={t("puzzleKind.ruleText")}
          variant="outlined"
          onChange={handleChangeRuleText}
          sx={{
            width: "100%",
          }}
          disabled={rules.length === 0}
          InputProps={{
            readOnly: mode === "view",
          }}
        />
        {(mode === "edit" || i18n.language === "ja") && (
          <>
            <TextField
              label={t("puzzleKind.author")}
              variant="standard"
              value={puzzleKindUnit.author.ja}
              onChange={(e) => {
                if (puzzleKindUnit === null) return;
                puzzleKindUnit.author.ja = e.target.value;
                setPuzzleKindUnit({
                  ...puzzleKindUnit,
                });
              }}
              sx={{
                my: 1,
                mx: 2,
                width: "200px",
              }}
              InputProps={{
                readOnly: mode === "view",
              }}
            />
            <TextField
              label={t("puzzleKind.firstAppeared")}
              variant="standard"
              value={puzzleKindUnit.firstAppearance.ja}
              onChange={(e) => {
                if (puzzleKindUnit === null) return;
                puzzleKindUnit.firstAppearance.ja = e.target.value;
                setPuzzleKindUnit({
                  ...puzzleKindUnit,
                });
              }}
              sx={{
                my: 1,
                mx: 2,
                width: "300px",
              }}
              InputProps={{
                readOnly: mode === "view",
              }}
            />
          </>
        )}

        {(mode === "edit" || i18n.language === "en") && (
          <>
            <TextField
              label={t("puzzleKind.authorEn")}
              variant="standard"
              value={puzzleKindUnit.author.en}
              onChange={(e) => {
                if (puzzleKindUnit === null) return;
                puzzleKindUnit.author.en = e.target.value;
                setPuzzleKindUnit({
                  ...puzzleKindUnit,
                });
              }}
              sx={{
                my: 1,
                mx: 2,
                width: "200px",
              }}
              InputProps={{
                readOnly: mode === "view",
              }}
            />
            <TextField
              label={t("puzzleKind.firstAppearedEn")}
              variant="standard"
              value={puzzleKindUnit.firstAppearance.en}
              onChange={(e) => {
                if (puzzleKindUnit === null) return;
                puzzleKindUnit.firstAppearance.en = e.target.value;
                setPuzzleKindUnit({
                  ...puzzleKindUnit,
                });
              }}
              sx={{
                my: 1,
                mx: 2,
                width: "300px",
              }}
              InputProps={{
                readOnly: mode === "view",
              }}
            />
          </>
        )}
        {mode === "edit" && (
          <TextField
            multiline
            rows={3}
            value={puzzleKindUnit.memo ?? ""}
            onChange={(e) => {
              if (puzzleKindUnit === null) return;
              puzzleKindUnit.memo = e.target.value;
              setPuzzleKindUnit({
                ...puzzleKindUnit,
              });
            }}
            label={t("puzzleKind.memo")}
            variant="outlined"
            sx={{
              width: "100%",
            }}
          />
        )}

        {mode === "edit" && (
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => {
                saveUnit();
                setIndex(index + 1);
              }}
              disabled={index === size - 1}
            >
              {t("保存して次")}
            </Button>
            <Button
              onClick={() => {
                saveUnit();
              }}
              variant="outlined"
            >
              {t("保存のみ")}
            </Button>
            <Button
              onClick={() => {
                setIndex(index + 1);
              }}
              disabled={index === size - 1}
              variant="outlined"
            >
              {t("保存せず次")}
            </Button>
          </ButtonGroup>
        )}
      </Paper>
    </Grid2>
  ) : (
    <></>
  );
};
