import { useEffect } from "react";
import { savePuzzleKindAll } from "../../api/firestore/loader/loadPuzzleKind";
import { usePuzzleKindData } from "../../hooks/PuzzleKindHooks";
import { PuzzleKindUnit } from "../../types/PuzzleKind";

export const Format = () => {
  const puzzleKindData = usePuzzleKindData();

  useEffect(() => {
    if (!puzzleKindData) {
      return;
    }

    // savePuzzleKind(puzzleKindData, "puzzleKindBackup2");
    // deletePuzzleKind("puzzleKinds");
    savePuzzleKindAll(
      {
        data: puzzleKindData.data,
      },
      "puzzleKindsBackup"
    );
  });

  return <p>formatted</p>;
};
