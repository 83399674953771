import { Box, CssBaseline, ThemeOptions, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import "firebase/auth";
import { MaintenanceHeader } from "../../common/MaintenanceHeader";
import { PageTitle } from "../../common/typography/PageTitle";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#212121",
    },
    secondary: {
      main: "#f50057",
    },
  },
};

const theme = createTheme(themeOptions);

export const Maintenance = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MaintenanceHeader />
      <Box sx={{ m: 1, mt: 10 }}>
        <PageTitle>メンテナンスのお知らせ</PageTitle>

        <p>Puzzle Square JPは9/23 1:00よりメンテナンス作業を行っております。</p>
        <p>
          メンテナンス中はパズルの表示や解答が行えませんので、メンテナンス終了をお待ちください。
        </p>
      </Box>
    </ThemeProvider>
  );
};
