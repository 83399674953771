import {
  arrayUnion,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { puzzleNameTable } from "../../../data/acceptPuzzlePlayerUrl";
import { PuzzleKindTable, PuzzleKindUnit } from "../../../types/PuzzleKind";
import { puzzleKindTableConverter } from "../converter/puzzleKindConverter";

export const loadPuzzleKind = async (
  setPuzzleKind: (prop: PuzzleKindTable | undefined) => void
) => {
  const db = getFirestore();
  const docRef = doc(db, "body", "puzzleKinds").withConverter(
    puzzleKindTableConverter
  );

  const stopRankingSnapshot = onSnapshot(docRef, (doc) => {
    const data = doc.data();
    console.log(data);
    setPuzzleKind(data);
  });
  return stopRankingSnapshot;
};

export const savePuzzleKind = async (
  puzzleKind: PuzzleKindUnit,
  name?: string
) => {
  const db = getFirestore();
  const docRef = doc(db, "body", name ?? "puzzleKinds").withConverter(
    puzzleKindTableConverter
  );
  const data: { [key: string]: any } = {};
  data["data." + puzzleKind.id] = puzzleKind;
  await updateDoc(docRef, data);

  const historyId = Math.random().toString(36).slice(-8);
  const historyRef = doc(db, "history", puzzleKind.id);
  const historyDoc = await getDoc(historyRef);
  if (historyDoc.exists()) {
    const history: { [key: string]: any } = {};
    history["data." + historyId] = puzzleKind;
    await updateDoc(historyRef, {
      data: history,
    });
  } else {
    const history: { [key: string]: any } = {
      data: {
        [historyId]: puzzleKind,
      },
    };
    await setDoc(historyRef, history);
  }
};

export const deletePuzzleKind = async (id: string, name?: string) => {
  const db = getFirestore();
  const docRef = doc(db, "body", name ?? "puzzleKinds").withConverter(
    puzzleKindTableConverter
  );
  const data: { [key: string]: any } = {};
  data["data." + id] = deleteField();
  await updateDoc(docRef, data);
};

export const savePuzzleKindAll = async (
  puzzleKind: PuzzleKindTable,
  name?: string
) => {
  const db = getFirestore();
  const docRef = doc(db, "body", name ?? "puzzleKinds").withConverter(
    puzzleKindTableConverter
  );
  await setDoc(docRef, puzzleKind).catch((error) => {
    console.error("Error writing document: ", error);
  });
};

export const deletePuzzleKindAll = async (name?: string) => {
  const db = getFirestore();
  const docRef = doc(db, "body", name ?? "puzzleKinds");
  await deleteDoc(docRef).catch((error) => {
    console.error("Error writing document: ", error);
  });
};
