import { Box } from "@mui/material";

export const NowLoading = () => {
  const rev = (Math.random() * 100 | 0) === 0;
  return (
    <>
      <Box sx={{ height: "70vh" }} />
      {rev ? (
        <div className="tetrominos">
          <div className="tetromino box1 rev"></div>
          <div className="tetromino box2 rev"></div>
          <div className="tetromino box3 rev"></div>
          <div className="tetromino box4 rev"></div>
        </div>
      ) : (
        <div className="tetrominos">
          <div className="tetromino box1"></div>
          <div className="tetromino box2"></div>
          <div className="tetromino box3"></div>
          <div className="tetromino box4"></div>
        </div>
      )}
    </>
  );
};
