import { AppBar, Toolbar, Typography } from "@mui/material";

export const MaintenanceHeader = () => {
  return (
    <>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: "pointer" }}
          >
            PuzzleSquareJP
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};
