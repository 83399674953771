import { useMemo } from "react";
import { usePuzzleKindData } from "../../hooks/PuzzleKindHooks";

export const Schema = () => {
  const puzzleKindData = usePuzzleKindData();

  const schema = useMemo(() => {
    if (!puzzleKindData) {
      return {};
    }
    const data = Object.values(puzzleKindData.data);
    const counter = data.reduce((acc, e) => {
      Object.keys(e).forEach((symbol) => {
        if (!acc[symbol]) {
          acc[symbol] = 0;
        }
        acc[symbol]++;
      });
      return acc;
    }, {} as Record<string, number>);
    const ret: {
      [key: string]: {
        type: string;
        nullable: boolean;
      };
    } = {};
    Object.keys(counter).forEach((symbol) => {
      ret[symbol] = {
        type: "string",
        nullable: counter[symbol] !== data.length,
      };
    });
    return ret;
  }, [puzzleKindData]);

  return (
    <>
      {Object.entries(schema).map((e) => {
        return (
          <p>
            {e[0]}: {e[1].type}
            {e[1].nullable ? "?" : ""}
          </p>
        );
      })}
    </>
  );
};
