import axios from "axios";
import { logger } from "../../../lib/logger";

type SuccessResponse = {
  status: "success";
  uid: string;
  customToken: string;
};

type ErrorResponse = {
  status: "error";
  uid: null;
  customToken: null;
};

const defaultErrorResponse = {
  status: "error",
  uid: null,
  customToken: null,
}

/**
 *
 * logicpuzzle.appのsessionCookieに紐づいたuidを返す。
 * sessionCookieが存在しない、または無効化されている場合はnullを返す。
 */

export const checkSessionCookie = async (): Promise<SuccessResponse | ErrorResponse> => {
  return await axios
    .get(
      `https://${process.env.REACT_APP_SITE_URL}/function/checkSessionCookie`,
      {
        withCredentials: true,
      }
    )
    .then((response) => {
      return response.data ?? defaultErrorResponse;
    })
    .catch((error) => {
      logger(error);
      return defaultErrorResponse;
    });
};
