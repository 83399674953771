import { Box, Container, Typography, Link } from "@mui/material";
import { FC } from "react";

export const CommonFooter: FC<{}> = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        my: 10,
      }}
    >
      <Container maxWidth="sm">
        <Typography variant="body1">
          Puzsq Rules is a Database of Logic Puzzle.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`v${process.env.REACT_APP_PACKAGE_VERSION} `}
          {"Copyright © "}
          <Link color="inherit" href={"https://logicpuzzle.app/"}>
            LogicPuzzleApp
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Container>
    </Box>
  );
};
