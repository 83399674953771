import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2"; // Grid version 2
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePuzzleKindData } from "../../hooks/PuzzleKindHooks";
import { isKanji } from "../../lib/charset";
import { MainGrid } from "./components/MainGrid";
import { SideList } from "./components/SideList";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const Edit = memo(() => {
  const puzzleKindData = usePuzzleKindData();
  const [index, setIndex] = useState(0);
  const [id, setId] = useState(window.location.hash.slice(1));
  const { i18n } = useTranslation();
  const [sortKey, setSortKey] = useState(i18n.language);
  const rows = useMemo(() => {
    if (puzzleKindData === null || puzzleKindData === undefined) return [];
    const ret = Object.values(puzzleKindData.data).sort(
      sortKey === "ja"
        ? (a, b) => {
            const aWord = !isKanji(a.nameJa)
              ? a.nameJa.replace(/[\u30a1-\u30f6]/g, function (match) {
                  var chr = match.charCodeAt(0) - 0x60;
                  return String.fromCharCode(chr);
                })
              : a.yomi ?? a.nameJa;
            const bWord = !isKanji(b.nameJa)
              ? b.nameJa.replace(/[\u30a1-\u30f6]/g, function (match) {
                  var chr = match.charCodeAt(0) - 0x60;
                  return String.fromCharCode(chr);
                })
              : b.yomi ?? b.nameJa;

            return aWord < bWord ? -1 : 1;
          }
        : (a, b) => {
            if (a.nameEn === b.nameEn) {
              const aWord = !isKanji(a.nameJa)
                ? a.nameJa.replace(/[\u30a1-\u30f6]/g, function (match) {
                    var chr = match.charCodeAt(0) - 0x60;
                    return String.fromCharCode(chr);
                  })
                : a.yomi ?? a.nameJa;
              const bWord = !isKanji(b.nameJa)
                ? b.nameJa.replace(/[\u30a1-\u30f6]/g, function (match) {
                    var chr = match.charCodeAt(0) - 0x60;
                    return String.fromCharCode(chr);
                  })
                : b.yomi ?? b.nameJa;
              return aWord < bWord ? -1 : 1;
            }
            if (a.nameEn === "") {
              return 1;
            }
            if (b.nameEn === "") {
              return -1;
            }
            return a.nameEn < b.nameEn ? -1 : 1;
          }
    );
    if (id === "") {
      setId(ret[index].id);
    } else {
      setIndex(ret.findIndex((e) => e.id === id));
    }
    return ret;
  }, [puzzleKindData, sortKey]);

  const size = useMemo(() => {
    if (!puzzleKindData) return 0;
    return Object.keys(puzzleKindData.data).length;
  }, [puzzleKindData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortKey((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <Grid2
        container
        spacing={2}
        sx={{
          height: "100%",
        }}
      >
        <MainGrid
          index={index}
          setIndex={setIndex}
          size={size}
          setId={setId}
          rows={rows}
          mode={"edit"}
        />
        <Grid2
          xs={4}
          sx={{
            height: "100%",
          }}
        >
          <Item>
            <Box
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                overflow: "scroll",
                "& ul": { padding: 0 },
                height: "100%",
                maxHeight: "80vh",
              }}
            >
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={sortKey}
                  onChange={handleChange}
                >
                  <FormControlLabel value="ja" control={<Radio />} label="Ja" />
                  <FormControlLabel value="en" control={<Radio />} label="En" />
                </RadioGroup>
              </FormControl>
            </Box>
            <SideList rows={rows} cursor={index} setIndex={setIndex} />
          </Item>
        </Grid2>
      </Grid2>
    </>
  );
});
