import { serverTimestamp } from "firebase/firestore";
import { useEffect } from "react";
import puzsq from "../../data/puzsq.json";
import { usePuzzleKindData } from "../../hooks/PuzzleKindHooks";
import { PuzzleKindUnit } from "../../types/PuzzleKind";

export const FromPuzsq = () => {
  const puzzleKindData = usePuzzleKindData();

  useEffect(() => {
    if (!puzzleKindData) {
      return;
    }
    const newPuzzleKindsMap = new Map(
      Object.values(puzzleKindData.data).map((e) => [e.nameJa, e])
    );
    puzsq.forEach((e) => {
      if (newPuzzleKindsMap.has(e.name)) {
        const puzzleKind = newPuzzleKindsMap.get(e.name);
        if (!puzzleKind) {
          return;
        }
        if (e.name_e !== "" && puzzleKind.nameEn === "") {
          const names = e.name_e.split(" / ");
          puzzleKind.nameEn = names[0];
          if (names.length > 1) {
            puzzleKind.akaEn = names.slice(1);
          }
        }
        if (e.q_img != "") {
          puzzleKind["examples"] = puzzleKind["examples"] ?? [];
          puzzleKind.examples.push({
            images: [e.q_img, e.a_img],
          });
        }
        if (e.rule_e != "") {
          puzzleKind["rules"] = puzzleKind["rules"] ?? [];
          puzzleKind.rules.push({
            text: e.rule_e,
            source: e.rule_source_e,
            language: "en",
          });
        }
        puzzleKind["puzsqId"] = e.id;
      } else {
        const data = {
          id: Math.random().toString(36).substring(7),
          puzsqId: e.id,
          nameJa: e.name,
          nameEn: e.name_e,
          firstAppearance: {
            ja: "",
            en: "",
          },
          author: {
            ja: "",
            en: "",
          },
          akaJa: [],
          akaEn: [],
          rules: [],
          examples: [],
          gridType: "",
          writeInCategories: "",
          writeInSubcategories: "",
          clueElements: "",
          symbols: "",
          ruleElements: "",
          memo: "",
          lastEditBy: "",
          lastEditAt: serverTimestamp(),
        } as PuzzleKindUnit;
        data["rules"].push({
          text: e.rule,
          source: e.rule_source,
          language: "ja",
        });
        data["rules"].push({
          text: e.rule_e,
          source: e.rule_source_e,
          language: "en",
        });

        if (e.q_img) {
          data.examples.push({
            images: [e.q_img, e.a_img],
          });
        }
        newPuzzleKindsMap.set(e.name, data);
      }
    });
    const newPuzzleKindData = Array.from(newPuzzleKindsMap.values()).sort(
      (a, b) => {
        if (a.nameJa < b.nameJa) {
          return -1;
        }
        if (a.nameJa > b.nameJa) {
          return 1;
        }
        return 0;
      }
    );

    /*
    savePuzzleKind(puzzleKindData, "puzzleKindBackup")
      .then((res) => {
        console.log("wrote backup", res);
      })
      .catch((err) => {
        console.log(err);
      });

    savePuzzleKind({
      data: newPuzzleKindData,
    })
      .then((res) => {
        console.log("wrote", res);
      })
      .catch((err) => {
        console.log(err);
      });*/
  }, [puzzleKindData]);

  return (
    <>
      <p>wrote.</p>
    </>
  );
};
