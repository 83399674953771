import { getAuth, signInWithCustomToken } from "firebase/auth";
import { logger } from "../../../lib/logger";
import { callDebugCreateCustomToken } from "./callDebugCreateCustomToken";

export const loginDebug = async () => {
  if (process.env.REACT_APP_CONTEXT !== "LOCAL") {
    return null;
  }
  logger("loginDebug", process.env.REACT_APP_UID);
  const auth = getAuth();
  const result = await callDebugCreateCustomToken(
    process.env.REACT_APP_UID ?? ""
  );
  console.log("result", result);
  return signInWithCustomToken(auth, result.customToken ?? "")
    .then((cred) => {
      return cred.user.uid;
    })
    .catch((error) => {
      logger(error);
      return null;
    });
};
