import {
  AppBar,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HideOnScroll } from "./slide/HideOnScroll";

export type CommonHeaderProps = {
  hideOnScroll?: boolean;
};

export const CommonHeader: React.FC<CommonHeaderProps> = (props) => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();

  return (
    <>
      <HideOnScroll {...props} active={!!props.hideOnScroll}>
        <AppBar position="fixed">
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, cursor: "pointer" }}
            >
              Puzsq Rules
            </Typography>
            <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
              <ToggleButtonGroup
                value={i18n.language}
                exclusive
                onChange={(_, value) => {
                  i18n.changeLanguage(value);
                }}
                aria-label="Language"
                sx={{
                  color: "primary.contrastText",
                  borderColor: "primary.contrastText",
                  bgcolor: "primary.light",
                }}
              >
                <ToggleButton
                  value="ja"
                  sx={{
                    color: "primary.contrastText",
                    borderColor: "primary.contrastText",
                    bgcolor: "primary.dark",
                  }}
                >
                  Ja
                </ToggleButton>
                <ToggleButton
                  value="en"
                  sx={{
                    color: "primary.contrastText",
                    borderColor: "primary.contrastText",
                    bgcolor: "primary.dark",
                  }}
                >
                  En
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  );
};

export {};
