import { useEffect, useState } from "react";
import { loadPuzzleKind } from "../api/firestore/loader/loadPuzzleKind";
import { PuzzleKindTable } from "../types/PuzzleKind";

let stopPuzzleKindSnapshot = () => {};
export const usePuzzleKindData = () => {
  const [puzzleKind, setPuzzleKind] = useState<
    null | PuzzleKindTable | undefined
  >(null);

  useEffect(() => {
    const f = async () => {
      stopPuzzleKindSnapshot();
      stopPuzzleKindSnapshot = await loadPuzzleKind(setPuzzleKind);
    };
    f();
  }, []);

  return puzzleKind;
};
