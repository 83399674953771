import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { BrowserRouter } from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyDwGHBzTpyANEOeIqsMShbfrsxrMick2OQ",
  authDomain: "puzsq-rules.firebaseapp.com",
  projectId: "puzsq-rules",
  storageBucket: "puzsq-rules.appspot.com",
  messagingSenderId: "895639450047",
  appId: "1:895639450047:web:aed602b6798a0092cdf10a",
  measurementId: "G-RCX21MQCY4"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app);
const REGION = "asia-northeast1";
export const functions = getFunctions(app, REGION);

if (process.env.REACT_APP_CONTEXT === "LOCAL") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "localhost", 5001);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
