import { httpsCallable } from "firebase/functions";
import { logger } from "../../../lib/logger";
import { functions } from "../../..";

export const callDebugCreateCustomToken = async (
  uid: string
): Promise<{ status: string; message?: string; customToken?: string }> => {
  const createDebugCustomTokenFunction = httpsCallable(
    functions,
    "createDebugCustomToken"
  );
  try {
    const result = await createDebugCustomTokenFunction({ uid });
    return result.data as {
      status: string;
      message?: string;
      customToken?: string;
    };
  } catch (e) {
    logger(e);
    return { status: "error", message: e as string };
  }
};
