import { loginDebug } from "../../api/cloudFunctions/sso/loginDebug";

type LoginProps = {
  setUid: React.Dispatch<React.SetStateAction<string | null>>;
};
export const Login = (props: LoginProps) => {
  const isLocalDebug = process.env.REACT_APP_CONTEXT === "LOCAL";
  loginDebug().then((result) => {
    if (result !== null) {
      props.setUid(result);
    }
  });

  return <></>;
};
