import { Box, ListItemButton, ListItemText } from "@mui/material";
import { i18n } from "i18next";
import { useTranslation } from "react-i18next";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { PuzzleKindUnit } from "../../../types/PuzzleKind";

type SideListProps = {
  rows: PuzzleKindUnit[];
  cursor: number;
  setIndex: (index: number) => void;
};

type RenderRowProps = SideListProps & { i18n: i18n };

const renderRow = (props: ListChildComponentProps<RenderRowProps>) => {
  const { index, style, data } = props;
  const { rows, cursor, setIndex, i18n } = data as RenderRowProps;
  const row = rows[index];

  return (
    <ListItemButton
      selected={cursor === index}
      onClick={(event) => setIndex(index)}
      key={index}
      style={style}
    >
      <ListItemText
        primary={
          i18n.language === "ja" || row.nameEn === "" ? row.nameJa : row.nameEn
        }
        secondary={
          i18n.language === "ja"
            ? (row.nameEn != "" && row.nameEn != row.nameJa
                ? row.akaJa.concat(row.nameEn)
                : row.akaJa
              ).join(", ")
            : row.akaEn.join(", ")
        }
      />
    </ListItemButton>
  );
};

export const SideList = (props: SideListProps) => {
  const { rows, cursor, setIndex } = props;
  const { i18n } = useTranslation();
  return rows ? (
    <Box
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        overflow: "scroll",
        "& ul": { padding: 0 },
        height: "100%",
        maxHeight: "80vh",
      }}
    >
      <FixedSizeList
        height={800}
        width={"100%"}
        itemSize={48}
        itemCount={rows.length}
        overscanCount={20}
        itemData={{ rows, cursor, setIndex, i18n }}
      >
        {renderRow}
      </FixedSizeList>
    </Box>
  ) : (
    <></>
  );
};
