import { useEffect } from "react";
import { savePuzzleKind } from "../../api/firestore/loader/loadPuzzleKind";
import initialData from "../../data/initialData.json";

export const Write = () => {
  console.log("write");
  return (
    <>
      <p>write</p>
    </>
  );
};
